<template>
  <div id="App">
    <Carousel :texta="false" :height="height" :imgList="imgList" />
    <div id="new">
      <div v-for="(index, i) in newtext" :key="i">
        <div class="text" v-if="index.type === 'text'">{{ index.value }}</div>
        <div class="image" v-if="index.type === 'img'">
          <img :src="index.img" alt="" />
        </div>
      </div>
      <div>
        《易校新》。校内交易平台：微信搜索“易手客”，立足海南，面向全国的大学生校内交易小程序专注大学二手交易专注校园生活是您学海护航的保姆
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  //数据
  data() {
    return {
      height: "600px",
      newtext: [],
      imgList: [
        require("@/assets/back.jpg"),
        require("@/assets/bigimg.jpg"),
        require("@/assets/bigimgC.jpg"),
      ],
    };
  },
  watch: {},
  computed: {},
  created() {
    console.log(decodeURIComponent(this.$route.query.id));

    this.newtext = JSON.parse(decodeURIComponent(this.$route.query.id));
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang='scss'>
#App {
  #new {
    margin: 20px auto;
    width: 62%;
    .text {
      font-size: 18px;
      margin: 20px 0;
    }
    .image {
      display: flex;
      justify-content: center;
      img {
        margin: auto;
      }
    }
  }
}
</style>
